import styled from "styled-components";
import {
  SVGRecord,
  SVGTable,
  SVGPhone,
  SVGPhoneX,
  SVGMapPinPerson,
  SVGList,
} from "../Assets/SVGTiles";
import { HomepageTile } from "../Components/HomepageTile";

import { IsAuthorizedUser } from "../Hooks/checkAuthorizedUser";
import { appRoles } from "../AppConfig";

const StyledDiv = styled.div`
  ${"" /* background-color: #fcfcff; */}
  ${"" /* box-shadow: var(--box-shadow-main); */}

  margin: 0px 35px 35px 35px;
  padding: 0px 40px 25px 40px;

  #title{
    margin: 10px 0px 20px 0px;
  }

 .horizontal-line {
    background-color: var(--lightgrey);
    height: 1px;
  }

  .homepage-description {
    margin: 10px 0;
  }

  ${
    "" /* .tile-container {
    justify-content: flex-start;
    flex-wrap: wrap;
  } */
  }

  .container-flex-row{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;

  }

  .container-flex-column{
    display: flex;
    flex-direction: column;
  }

  .service-title{
    margin-top: 1rem;

    font-family: EMprintLight,HelveticaNeue,Helvetica,Arial,sans-serif; */
    font-weight: 400;
    font-style: normal;
    font-size: 1.5rem;
    line-height: 1; 
    margin-bottom: 1rem;
    
  }


`;

export const Home = () => {
  return (
    <StyledDiv>
      <main>
        <div className="container-flex-column">
          <h5 className="service-title">Zoom Phone Request.</h5>
          <div className="container-flex-row">
            <HomepageTile
              title="Zoom Phone Request Form"
              svg={SVGPhone}
              redirectLink="/phone-request"
              external
            >
              {/* flash left */}
              <p>
                Click here to access the Zoom Phone Request Form. This form is
                used to request a Zoom Phone number for external calling. Only
                those located at approved locations may request a Zoom Phone
                number at this time.
                <br></br>
                <b>For those requesting a number during a location rollout</b>,
                it also starts the countdown to when your Skype client is either
                downgraded to remove your number and/or the Skype client is
                removed completely (each location is different). Read your
                rollout communications for details and dates.
                <br></br>
                <b>For those requesting after an implementation</b>, be sure to
                follow the instructions in goto/NumberTransition so your
                contacts are notified and printed locations updated.
                {/* &nbsp; */}
              </p>
            </HomepageTile>

            <HomepageTile
              title="Zoom Phone Location Update"
              svg={SVGMapPinPerson}
              redirectLink="/phone-move-form"
              external
            >
              <p>
                Click here to update your Zoom phone location. You are only
                allowed to move locations within your stated country. If the
                country displayed on the form is incorrect, you must first
                update IDM, wait 24 hours to populate, then you may update your
                Zoom phone location.
              </p>
            </HomepageTile>

            <HomepageTile
              title="Zoom Phone Return Form"
              svg={SVGPhoneX}
              redirectLink="/phone-release-form"
              external
            >
              <p>
                Click here to request that your Zoom Phone number be unassigned
                and returned. You may use this form to return one or more
                numbers, but only those assigned to you. Should you need a new
                Zoom number again in the future, goto/getnumber to submit a new
                request.
              </p>
            </HomepageTile>

            <HomepageTile
              title="Submitted Zoom Phone Requests"
              svg={SVGTable}
              redirectLink="/phone-request-logs"
              external
            >
              <p>
                Click here to view your Zoom Phone and Zoom Phone Location
                submissions. You will only see requests associated with you.
              </p>
            </HomepageTile>
            {IsAuthorizedUser(appRoles.AdminPhoneMetrics) && (
              <HomepageTile
                title="Phone Number Metrics"
                svg={SVGTable}
                redirectLink="/phone-number-metrics"
                external
              >
                <p>Click here to view phone number metrics.</p>
              </HomepageTile>
            )}

            {IsAuthorizedUser(appRoles.AdminPhoneOnboard) && (
              <HomepageTile
                title="Onboard Zoom Phone sites"
                svg={SVGList}
                redirectLink="/zoom-onboard-site-form"
                external
              >
                <p>
                  Click here to add a Zoom phone site into phone 
                  request onboarded site list.
                </p>
              </HomepageTile>
            )}

          </div>
          <br></br>

          

          <div className="horizontal-line" />
          <h5 className="service-title">Zoom Meeting Recording Request</h5>
          <div className="container-flex-row ">
            <HomepageTile
              title="Zoom Meeting Recording Request Form"
              svg={SVGRecord}
              redirectLink="/zoom-recording"
              external
            >
              <p>
                Click here to submit a request to be granted temporary 
                functionality in Zoom to record a meeting or webinar.
              </p>
            </HomepageTile>
            <HomepageTile
              title="Submitted Zoom Meeting Recording Requests"
              svg={SVGTable}
              redirectLink="/zoom-recording-data"
            >
              <p>
                Click here to check the status of your Zoom Meeting Recording 
                Request.
              </p>
            </HomepageTile>
          </div>

          {IsAuthorizedUser(appRoles.AdminRoomsCode) && (
            <div>
              <h5 className="service-title">Zoom Rooms.</h5>

              <div className="container-flex-row ">
                <HomepageTile
                  title="Zoom Room Activation Code Request Form"
                  svg={SVGTable}
                  redirectLink="/zoom-room-form"
                  external
                >
                  <p>
                    Click here to request a Zoom Room activation code. The code
                    is displayed onscreen immediately upon request.
                  </p>
                </HomepageTile>
              </div>
            </div>
          )}
          <div>
              <h5 className="service-title">Zoom Phone EXCEPTION Request.</h5>

              <div className="container-flex-row ">
                <HomepageTile
                  title="Zoom Phone Foreign EXCEPTION Request"
                  svg={SVGPhone}
                  redirectLink="/phone-request-foreign"
                  external
                >
                  <p>
                    Click here to access the Zoom Phone Foreign Request. This form is
                    used to request a Zoom Phone number from an external site that you are not based there.
                    <br></br>
                  </p>
                </HomepageTile>
                <HomepageTile
                  title="Submitted Zoom Phone EXCEPTIONS Requests"
                  svg={SVGTable}
                  redirectLink="/phone-request-foreign-logs"
                  external
                >
                  <p>
                    Click here to view your Zoom Phone EXCEPTION 
                    submissions. You will only see requests associated with you.
                  </p>
                </HomepageTile>
              </div>
            </div>
          </div>
      </main>
    </StyledDiv>
  );
};
